const CURRENT_ENV = process.env.NEXT_PUBLIC_ENV as string;

export const ENV = {
  ENV: CURRENT_ENV,
  IS_PRODUCTION: CURRENT_ENV === 'production',
  URL: process.env.NEXT_PUBLIC_URL as string,
  API_URL: process.env.NEXT_PUBLIC_API_URL as string,
  SEGMENT_WRITE_KEY: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY as string,
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN as string,
  GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,
  INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID as string,
  FIREBASE: {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  },
  STRIPE: {
    pricingTableId: process.env.NEXT_PUBLIC_STRIPE_PRICING_TABLE_ID,
    publishableKey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  },
};
